import { useLogin } from "~api/auth/hooks";
import type { PostLoginRequest } from "~api/auth/types";
import { useTranslate } from "~i18n/hooks";
import { UsernameTypeEnum } from "~src/templates/login/types";
import { useOpenSnackBar } from "~store/snack-bar/hooks";
import { voidFunction } from "~utils/functions";
import { Paths } from "~utils/paths";
import { translateTheApp } from "~utils/translation-utils";
import type { Consumer, TSubmitDescriptor } from "~utils/types";
import type { User } from "~utils/types/global-types";

import type { FormLoginInput } from "./types";

import { AxiosError } from "axios";
import { useRouter } from "next/router";

export const useSubmitLogin = (): TSubmitDescriptor<User, FormLoginInput> => {
  const { openSnackBar } = useOpenSnackBar();
  const translate = useTranslate();
  const { push: navigateTo } = useRouter();

  const onLoginSuccess: Consumer<User> = (user) => {
    const language = user.hotel.defaultLanguage;
    translateTheApp(language);

    if (user.passwordChanged) {
      navigateTo(Paths.Tasks).catch(voidFunction);
    } else {
      navigateTo(Paths.UpdatePassword).catch(voidFunction);
    }
  };

  const onLoginError: Consumer<unknown> = (error) => {
    if (!(error instanceof AxiosError) || error.response === undefined || error.response.status >= 500)
      openSnackBar({ message: translate("common.errors.generic"), severity: "error" });
    else {
      openSnackBar({ message: translate("common.errors.bad_credentials"), severity: "error" });
    }
  };

  const { mutate, ...queryResultProps } = useLogin(onLoginSuccess, onLoginError);
  return {
    ...queryResultProps,
    onSubmit: (formLoginInput) => {
      const { type, email, phone, username, password } = formLoginInput;

      let loginCredential;
      if (type === UsernameTypeEnum.EMAIL) {
        loginCredential = email;
      } else if (type === UsernameTypeEnum.PHONE) {
        loginCredential = phone;
      } else {
        loginCredential = username;
      }

      const dto: PostLoginRequest = {
        username: loginCredential,
        password,
      };
      mutate(dto);
    },
  };
};
