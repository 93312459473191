import theme from "~providers/muiThemeProvider/mui-theme";
import PrimaryButton from "~src/common/components/buttons/primaryButton/PrimaryButton";
import CustomSelect from "~src/common/components/customSelect";
import { Column } from "~src/common/components/flexs/Column";
import { Row } from "~src/common/components/flexs/Row";
import RoundTextField from "~src/common/components/inputs/roundTextField/RoundTextField";
import { sizes } from "~utils/responsive/breakPointsResponsive";

import styled from "@emotion/styled";
import { FormHelperText, Paper } from "@mui/material";

export const Commons = {
  Wrapper: styled.div`
    padding: 35px;
    position: relative !important;
    @media (max-width: ${sizes.sm}) {
      padding-inline: 15px;
    }
  `,
  SubTitle: styled.div`
    font-size: 22px;
    margin-bottom: 20px;
    color: ${theme.palette.grey[900]};
    font-weight: bold;
    margin-top: 10px;
  `,
  Content: styled(Row)`
    margin-top: 30px;
    align-items: normal;
    gap: 30px;
    @media (max-width: ${sizes.sm}) {
      flex-direction: column;
    }
  `,
  LeftWrapper: styled(Column)`
    width: 55%;
    gap: 20px;

    @media (max-width: ${sizes.sm}) {
      width: 100%;
    }
  `,
  RightWrapper: styled.div`
    width: 45%;
    @media (max-width: ${sizes.sm}) {
      width: 100%;
    }
  `,
  LeftContent: styled(Paper)`
    padding: 30px;
    @media (max-width: ${sizes.sm}) {
      padding-inline: 15px;
    }
  `,
  RightContent: styled(Paper)`
    padding: 30px;
    background-color: ${theme.palette.primary[100]};
    @media (max-width: ${sizes.sm}) {
      padding-inline: 15px;
    }
  `,
  StyledSelect: styled(CustomSelect)`
    width: 45%;

    @media (max-width: ${sizes.sm}) {
      width: 100%;
    }
  `,
  SubmitButtonsContainer: styled(Row)`
    margin-top: ${(props: { topGap: number }) => `${props.topGap}px`};
    justify-content: center;
    gap: 50px;
  `,
  SubmitButton: styled(PrimaryButton)`
    padding-inline: 40px !important;
  `,
  InputErrorText: styled(FormHelperText)`
    margin-left: 14px;
  `,
  StyledTextInput: styled(RoundTextField)`
    background-color: ${theme.palette.common.white};
  `,
  PaperWrapper: styled(Paper)`
    width: 100%;
    padding: 30px;

    @media (max-width: ${sizes.sm}) {
      padding-inline: 15px;
    }
  `,
  FullWidthWrapper: styled.div`
    width: 100%;
  `,
  RowToColumnWrapper: styled(Row)`
    gap: 30px;
    @media (max-width: ${sizes.sm}) {
      flex-direction: column;
    }
  `,
};
