import { useTranslate } from "~i18n/hooks";
import theme from "~providers/muiThemeProvider/mui-theme";
import Logo from "~src/assets/icons/logo";
import { Column } from "~src/common/components/flexs/Column";
import { Body2 } from "~src/common/components/typography/Body2";
import { H3 } from "~src/common/components/typography/H3";

import type { FC } from "react";

const LoginCardHeader: FC = () => {
  const translate = useTranslate();
  return (
    <Column alignItems="center">
      <Logo />
      <H3 value={translate("login.card.title")} weight="bold" color={theme.palette.primary[500]} />
      <Body2 value={translate("login.card.subTitle")} color={theme.palette.grey[500]} textAlign="center" />
    </Column>
  );
};

export default LoginCardHeader;
