import { useTranslate } from "~i18n/hooks";
import type { TxKeyPath } from "~i18n/types";
import CustomPhoneInput from "~src/common/components/customPhoneInput";
import CustomSelect from "~src/common/components/customSelect";
import { Column } from "~src/common/components/flexs/Column";
import { Row } from "~src/common/components/flexs/Row";
import PasswordTextField from "~src/common/components/inputs/passwordTextField";
import RoundTextField from "~src/common/components/inputs/roundTextField";
import LabelWithInput from "~src/common/components/labelWithInput";
import Loading from "~src/common/components/loading";
import { UsernameTypeEnum } from "~src/templates/login/types";
import { isDefined, isNullOrUndefined } from "~utils/boolean-utils";
import { VALID_EMAIL_REGEXP, VALID_PHONE_REGEXP } from "~utils/constants";
import { voidFunction } from "~utils/functions";
import { storeLoginCredential } from "~utils/localstorage-utils";
import { Paths } from "~utils/paths";
import type { Nullable } from "~utils/types";

import type { FormLoginInput } from "./service/types";
import { useSubmitLogin } from "./service/useSubmitLogin";
import Sc from "./LoginCardBody.style";

import { yupResolver } from "@hookform/resolvers/yup";
import { MenuItem } from "@mui/material";
import { useRouter } from "next/router";
import type { FC } from "react";
import { Controller, useForm } from "react-hook-form";
import { object, string } from "yup";

const LoginCardBody: FC = () => {
  const { onSubmit, isLoading } = useSubmitLogin();
  const translate = useTranslate();
  const { push: navigateTo } = useRouter();

  const loginValidationSchema = object({
    email: string().when("type", {
      is: UsernameTypeEnum.EMAIL,
      then: string()
        .matches(VALID_EMAIL_REGEXP, translate("common.errors.invalid_field"))
        .required(translate("common.errors.required")),
    }),
    phone: string().when("type", {
      is: UsernameTypeEnum.PHONE,
      then: string()
        .matches(VALID_PHONE_REGEXP, translate("common.errors.invalid_field"))
        .required(translate("common.errors.required")),
    }),
    username: string().when("type", {
      is: UsernameTypeEnum.USERNAME,
      then: string().required(translate("common.errors.required")),
    }),
    password: string().required(translate("common.errors.required")),
  });

  const {
    control,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<FormLoginInput>({
    defaultValues: {
      type: UsernameTypeEnum.PHONE,
    },
    resolver: yupResolver(loginValidationSchema) as never,
  });

  const usernameType = watch("type");
  const email = watch("email");
  const phone = watch("phone");
  const username = watch("username");

  const getLoginCredential = (): Nullable<string> => {
    if (usernameType === UsernameTypeEnum.EMAIL && isNullOrUndefined(errors.email)) {
      return email;
    }
    if (usernameType === UsernameTypeEnum.PHONE && isNullOrUndefined(errors.phone)) {
      return phone;
    }
    if (isNullOrUndefined(errors.username)) {
      return username;
    }
    return null;
  };
  const onClickForgotPassword = () => {
    const currentLoginCredential = getLoginCredential();
    if (isDefined(currentLoginCredential)) {
      storeLoginCredential(currentLoginCredential);
    }

    navigateTo(Paths.ForgotPassword).catch(voidFunction);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Column alignItems="center" rowGap={66}>
        <Column rowGap={10}>
          <Sc.InputsContainer>
            <LabelWithInput label={translate("login.card.type")}>
              <Controller
                name="type"
                control={control}
                render={({ field }) => (
                  <CustomSelect backgroundColor="transparent" {...field}>
                    {Object.values(UsernameTypeEnum).map((type) => (
                      <MenuItem key={type} value={type}>
                        {translate(`login.card.username_types.${type}` as TxKeyPath)}
                      </MenuItem>
                    ))}
                  </CustomSelect>
                )}
              />
            </LabelWithInput>
            {usernameType === UsernameTypeEnum.PHONE && (
              <LabelWithInput label={translate("login.card.username_types.phone")}>
                <Controller
                  name="phone"
                  control={control}
                  render={({ field }) => (
                    <CustomPhoneInput {...field} error={errors.phone?.message} backgroundColor="transparent" />
                  )}
                />
              </LabelWithInput>
            )}
            {usernameType === UsernameTypeEnum.EMAIL && (
              <LabelWithInput label={translate("login.card.username_types.email")}>
                <Controller
                  name="email"
                  control={control}
                  render={({ field }) => (
                    <RoundTextField {...field} error={Boolean(errors.email)} helperText={errors.email?.message} />
                  )}
                />
              </LabelWithInput>
            )}
            {usernameType === UsernameTypeEnum.USERNAME && (
              <LabelWithInput label={translate("login.card.username_types.username")}>
                <Controller
                  name="username"
                  control={control}
                  render={({ field }) => (
                    <RoundTextField {...field} error={Boolean(errors.username)} helperText={errors.username?.message} />
                  )}
                />
              </LabelWithInput>
            )}
            <LabelWithInput label={translate("login.card.password.label")}>
              <Controller
                name="password"
                control={control}
                render={({ field }) => (
                  <PasswordTextField
                    {...field}
                    error={Boolean(errors.password)}
                    helperText={errors.password?.message}
                  />
                )}
              />
            </LabelWithInput>
          </Sc.InputsContainer>
          <Row justify="flex-end">
            <Sc.ForgetPasswordButton onClick={onClickForgotPassword} value={translate("login.card.forget_password")} />
          </Row>
        </Column>
        {isLoading ? (
          <Loading />
        ) : (
          <Sc.StyledPrimaryButton value={translate("login.card.submitButton")} variant="contained" type="submit" />
        )}
      </Column>
    </form>
  );
};

export default LoginCardBody;
